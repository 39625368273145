import { useMutation, useQuery } from '@apollo/client';
import { Add, Delete } from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { LoadingButton } from '@mui/lab';
import Check from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import { DOCUMENTS_TYPES, INVOICE_ATTACHABLE_DOCUMENTS, UPLOAD_INVOICE_DOCUMENTS } from '../../graphql';
import { AttachedDocuments } from '../attach';
import { FingoDialog } from '../dialogs';
import { documentToAttachableList } from '../../helpers/document-to-attachable-list';
import useGetInvoice from '../../hooks/useGetInvoice';
import { formatRut } from '../../helpers';
import { useGetUser } from '../../hooks';

const UploadDocumentsManager = ({
  open,
  handleClose,
  invoiceId,
}) => {
  const [documents, setDocuments] = useState([{
    name: '',
    documentType: '',
    file: '',
  }]);
  const { data: documentsData } = useQuery(DOCUMENTS_TYPES, { skip: !open });
  const [uploadFilesMessage, setUploadFilesMessage] = useState('');
  const currentUser = useGetUser();
  const [uploadFiles, { loading: loadingUploadFiles }] = useMutation(
    UPLOAD_INVOICE_DOCUMENTS,
    {
      variables: {
        invoiceId,
        inputUploadFiles: documents.map(({ documentType, file }) => (
          { documentType, file }
        )),
      },
      onCompleted: () => {
        setDocuments([{
          name: '',
          documentType: '',
          file: '',
        }]);
        setUploadFilesMessage('');
      },
      onError: () => {
        setUploadFilesMessage('Ha ocurrido un error. Verifique que los archivos estén correctos.');
      },
    },
  );
  const invoice = useGetInvoice(open && invoiceId, INVOICE_ATTACHABLE_DOCUMENTS);
  const company = useSelectedCompany();
  const hasInvoiceProvider = invoice?.company?.hasInvoiceProviderCredentials;
  const attachableList = invoice ? documentToAttachableList(invoice) : [];
  const setDocumentType = useCallback((e, i) => {
    const { value } = e.target;
    setDocuments((previous) => {
      const newDocuments = [...previous];
      newDocuments[i].documentType = value;
      return newDocuments;
    });
  }, []);

  const handleAddDocumentInput = useCallback(() => {
    setDocuments((prev) => [
      ...prev,
      {
        name: '',
        documentType: '',
        file: '',
      },
    ]);
  }, []);

  const handleRemoveDocument = useCallback((i) => {
    setDocuments((prev) => prev.slice(0, i).concat(prev.slice(i + 1, prev.length)));
  }, []);

  const handleAddDocument = useCallback((file, i) => {
    setDocuments((previous) => {
      const newDocuments = [...previous];
      newDocuments[i].name = file.name;
      newDocuments[i].file = file;
      return newDocuments;
    });
  }, []);

  // Handle uploaded documents
  const NOT_UNIQUE_DOCUMENTS = ['OTHER'];
  const checkAlreadyUploaded = useCallback((method) => {
    if (NOT_UNIQUE_DOCUMENTS.includes(method.code)) {
      return false;
    }
    if (['PDF', 'XML'].includes(method.code) && hasInvoiceProvider) {
      return true;
    }
    return attachableList.some(
      (attachable) => attachable.label === method.name,
    );
  }, [hasInvoiceProvider, attachableList]);
  const disabledButtons = useMemo(
    () => documents.some(({ name, documentType }) => name === '' || documentType === ''),
    [documents],
  );
  const checkXmlErrors = (documentType, documentName) => {
    if (documentType !== 'XML' && documentName.includes('.xml')) {
      return '👀 Recuerda que existe una sección para los XML de la factura';
    } if (documentType === 'XML' && !documentName.includes('.xml') && documentName) {
      return '🤔 El archivo cargado no es de tipo XML';
    }
    return false;
  };
  return (
    <FingoDialog open={open} handleClose={handleClose} title="Carga de Documentos">
      <Typography>
        Cargando documentos a factura {' '}
        {invoice?.folio} de {invoice?.company?.name} ({formatRut(invoice?.company?.rut)})
      </Typography>
      <Stack spacing={1} sx={{ paddingTop: 2 }}>
        {documents.map(({ name, documentType, file }, i) => {
          const rowError = checkXmlErrors(documentType, name);
          return (
            <Stack key={name || 'new-file'} direction="row" alignItems="center" spacing={1}>
              <FormControl fullWidth variant="standard" sx={{ width: 250 }}>
                <InputLabel id="document-input-label" error>Tipo documento</InputLabel>
                <Select
                  labelId="document-input-label"
                  id="document-input"
                  value={documentType}
                  onChange={(e) => setDocumentType(e, i)}
                  fullWidth
                >
                  {documentsData?.documentsTypes.map((document) => (
                    <MenuItem
                      key={document.id}
                      value={document.code}
                      disabled={currentUser?.isStaff ? false : checkAlreadyUploaded(document)}
                    >
                      <ListItemText>
                        {document.name}{' '}
                        {checkAlreadyUploaded(document) && (
                        <>
                          ya está cargado!
                        </>
                        )}
                      </ListItemText>
                      {checkAlreadyUploaded(document) && (
                      <ListItemIcon size="small">
                        <Check color="success" />
                      </ListItemIcon>
                      )}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(rowError) && <FormHelperText>{rowError}</FormHelperText>}
              </FormControl>
              <label htmlFor={i}>
                <input
                  style={{ display: 'none' }}
                  id={i}
                  type="file"
                  onChange={(e) => handleAddDocument(e.target.files[0], i)}
                />
                <IconButton aria-label="attach" component="span" size="small" id="uploadDocumentAttach">
                  <AttachFileIcon />
                </IconButton>
              </label>
              <IconButton onClick={() => handleRemoveDocument(i)} size="small" id="uploadDocumentRemove">
                <Delete />
              </IconButton>
              <Box p={0.2}>
                {file === '' ? (
                  <ErrorIcon color="error" size="small" />
                ) : (
                  <Check color="success" size="small" />
                )}
              </Box>
            </Stack>
          );
        })}
      </Stack>
      <Box mt={2}>
        <Typography><b>{uploadFilesMessage}</b></Typography>
      </Box>
      <Stack mt={2} alignItems="center" justifyContent="space-between" direction="row">
        <Button
          color="primary"
          variant="text"
          startIcon={<Add />}
          onClick={handleAddDocumentInput}
          size="small"
          id="uploadDocumentAdd"
          disabled={disabledButtons}
        >
          Agregar documento
        </Button>
        <LoadingButton
          variant="contained"
          onClick={uploadFiles}
          color="primary"
          autoFocus
          size="small"
          disabled={disabledButtons || documents.length === 0}
          loading={loadingUploadFiles}
          id="uploadDocumentUpload"
        >
          Cargar
        </LoadingButton>
      </Stack>
      <Divider sx={{ marginBlock: 1 }}>Documentos cargados</Divider>
      <AttachedDocuments invoiceId={invoiceId} />
    </FingoDialog>
  );
};

UploadDocumentsManager.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  invoiceId: PropTypes.string.isRequired,
};

export default UploadDocumentsManager;
