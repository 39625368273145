import React from 'react';
import { MentionsInput } from 'react-mentions';
import makeStyles from '@mui/styles/makeStyles';

export const mentionInputClassName = 'ssky-mention-input';
export const floatingLabelClassName = 'ssky-mention-input-floating-label';

const paddingTop = '15px';
const paddingHorizontal = '10px';
const padding = `${paddingTop} ${paddingHorizontal}`;
const fontSize = 16;

const useStyles = makeStyles(({ palette, transitions }) => ({
  root: {
    width: '100%',

    '& &__control': {
      fontSize,
    },

    '& &__input': {
      padding,
      border: 0,
      color: 'transparent',
      caretColor: palette.text.primary,
      transition: transitions.create('border-bottom-color', {
        duration: transitions.duration.shorter,
        easing: transitions.easing.easeInOut,
      }),
      '&:focus': {
        outline: 'none',
        borderBottom: 'none',
      },
      '&:disabled': {
        color: palette.text.disabled,
        borderBottom: 'none',
      },
      '&:hover:not(:disabled):not(:focus)': {
        borderBottom: 'none',
      },
    },

    '& &__highlighter': {
      padding,
      color: `${palette.text.primary} !important`,
      '&__substring': {
        visibility: 'inherit !important',
      },
    },

    '& &__suggestions': {
      backgroundColor: 'white',
      border: 'none',
      boxShadow: `0 0 8px ${palette.text.primary}`,
      width: 'max-content',
      maxHeight: 300,
      overflow: 'auto',
    },

    '& &__suggestions__item': {
      display: 'flex',
      alignItems: 'center',
      height: 48,
      transition: transitions.create('background-color', {
        duration: transitions.duration.shortest,
        easing: transitions.easing.easeInOut,
      }),
      padding: 15,
    },

    '& &__suggestions__item--focused': {
      backgroundColor: palette.action.selected,
    },
  } }));

const CustomMentionsInput = (props) => {
  const classes = useStyles();
  return (
    <MentionsInput {...props} className={classes.root} />
  );
};

export default CustomMentionsInput;
