import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import { IconButton, Tooltip, Menu } from '@mui/material';
import { FETCH_COMPANY_CTE, FETCH_COMPANY_TGR } from '../../graphql/customers';
import ClientDocumentsItem from './ClientDocumentItem';
import ClientCTEandTGRDocumentsItem from './ClientCTEandTGRDocumentItem';

const documentsList = [
  { key: 1, id: 'contract', name: 'Contrato', download: true, fetch: false, mutation: FETCH_COMPANY_TGR, divider: true, component: ClientDocumentsItem },
  { key: 2, id: 'tgr', name: 'TGR', download: true, fetch: true, mutation: FETCH_COMPANY_TGR, divider: true, component: ClientCTEandTGRDocumentsItem },
  { key: 3, id: 'cte', name: 'CTE', download: true, fetch: true, mutation: FETCH_COMPANY_CTE, divider: true, component: ClientCTEandTGRDocumentsItem },
  { key: 4, id: 'eerr', name: 'EERR', download: true, fetch: false, mutation: FETCH_COMPANY_TGR, divider: true, component: ClientDocumentsItem },
  { key: 5, id: 'balance', name: 'Balance', download: true, fetch: false, mutation: FETCH_COMPANY_TGR, divider: false, component: ClientDocumentsItem },
  // { id: 'invoicer', name: 'C. Facturador', download: false, fetch: false, divider: false },
];

const ClientDocumentsMenu = ({ companyId, documents }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const openMenu = useCallback((event) => setAnchorEl(event.currentTarget), []);

  const sortedDocuments = [...documents].sort((a, b) => {
    const idA = parseInt(a.id, 10);
    const idB = parseInt(b.id, 10);
    return idB - idA;
  });

  return (
    <>
      <Tooltip title="Documentos Asociados">
        <IconButton
          onClick={openMenu}
          size="large"
          color="primary"
          id="clientDocuments"
        >
          <InsertDriveFile />
        </IconButton>
      </Tooltip>
      <Menu
        keepMounted
        sx={{ display: 'inline' }}
        anchorEl={anchorEl}
        onClose={closeMenu}
        open={Boolean(anchorEl)}
        id={`documents-company-${companyId}-menu`}
      >
        {documentsList.map((documentMaper) => (
          <documentMaper.component
            key={documentMaper.id}
            documentMaper={documentMaper}
            companyId={companyId}
            document={sortedDocuments.find((element) => element.documentType === documentMaper.id)}
          />
        ))}
      </Menu>
    </>
  );
};

ClientDocumentsMenu.propTypes = {
  companyId: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    globalAppId: PropTypes.string.isRequired,
    lastDate: PropTypes.string.isRequired,
    documentType: PropTypes.string.isRequired,
    lastFile: PropTypes.string.isRequired,
  })).isRequired,
};

export default ClientDocumentsMenu;
