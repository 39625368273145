import { TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { validationTypes } from '../../helpers';

function ValidationTextField({
  Component,
  value,
  validationtype,
  helperTextColor,
  showErrorMessage,
  ...other
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const handleOnBlur = useCallback(() => {
    const { truthFunction, message } = validationTypes(value)[validationtype];
    if (!truthFunction() && value) {
      setErrorMessage(message);
    }
  }, [value]);

  useEffect(() => {
    const { truthFunction } = validationTypes(value)[validationtype];
    if (truthFunction() || !value) {
      setErrorMessage('');
    }
  }, [value, validationtype]);

  return (
    <Component
      onBlur={handleOnBlur}
      error={Boolean(errorMessage)}
      helperText={(
        <Typography variant="caption" color={helperTextColor}>
          {showErrorMessage && errorMessage}
        </Typography>
      )}
      value={value}
      {...other}
    />
  );
}
export default ValidationTextField;

ValidationTextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  validationtype: PropTypes.oneOf(['email', 'phone', 'text', 'rut', 'rfc']),
  // eslint-disable-next-line react/forbid-prop-types
  Component: PropTypes.object,
  helperTextColor: PropTypes.string,
  showErrorMessage: PropTypes.bool,
};

ValidationTextField.defaultProps = {
  validationtype: 'email',
  value: undefined,
  Component: TextField,
  helperTextColor: 'error',
  showErrorMessage: true,
};
