import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FingoTextField } from '../fields';
import useDebounceState from '../../hooks/useDebounceState';

const HeaderSearcherInput = ({ finder }) => {
  const [finderValue, setFinderValue, handleOnChangeEvent] = useDebounceState(
    finder?.finderValue,
    finder.onFinderChange,
  );
  const handleDrop = (event) => {
    setFinderValue(event.newData);
  };

  return (
    <Box
      width={{
        xs: '100%',
        md: 350,
      }}
      maxWidth={{
        md: 350,
        xs: '100%',
      }}
      mt={{
        xs: 2,
        md: 'inherit',
      }}
    >
      <FingoTextField
        value={finderValue}
        onChange={handleOnChangeEvent}
        onDrop={handleDrop}
        onDragOver={(e) => { e.preventDefault(); }}
        placeholder={finder.searchPlaceHolder}
        canPasteExcel={finder.canPasteExcel}
        setColumnFilter={finder.setColumnFilter}
        onChangeExcel={finder.onChangeExcel}
      />
    </Box>
  );
};

HeaderSearcherInput.propTypes = {
  finder: PropTypes.shape({
    onFinderChange: PropTypes.func.isRequired,
    searchPlaceHolder: PropTypes.string.isRequired,
    finderValue: PropTypes.string.isRequired,
    canPasteExcel: PropTypes.bool,
    setColumnFilter: PropTypes.func,
    onChangeExcel: PropTypes.func,
  }).isRequired,
};

export default HeaderSearcherInput;
